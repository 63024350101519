import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import './App.css';
import Login from './components/Login';
import Logout from './components/Logout';
import LogoImage from './LogoImage';
import LogoText from './LogoText';
import Carousel from './components/Carousel'; // Import the Carousel component

const TRACKING_ID = "G-QEHWGR0TER"; // Replace with your actual tracking ID

function App() {
  const location = useLocation();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  useEffect(() => {
    ReactGA.initialize([{ trackingId: TRACKING_ID }]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  const currentYear = new Date().getFullYear(); // Get the current year dynamically

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <div className="App">
      <nav className="navbar">
        <div className="logo-container">
          <LogoImage />
          <LogoText />
        </div>
        <div className="nav-links">
          <a href="https://hive.trading/about.html" onClick={(e) => { e.preventDefault(); window.location.href = 'https://hive.trading/about.html'; }}>About Us</a>
          <a href="https://hive.trading/strategies.html" onClick={(e) => { e.preventDefault(); window.location.href = 'https://hive.trading/strategies.html'; }}>Strategies</a>
          <a href="https://hive.trading/faq.html" onClick={(e) => { e.preventDefault(); window.location.href = 'https://hive.trading/faq.html'; }}>FAQ</a>
          <a href="https://hive.trading/help.html" onClick={(e) => { e.preventDefault(); window.location.href = 'https://hive.trading/help.html'; }}>Help</a>
        </div>
        <div className="mobile-nav-toggle" onClick={toggleMobileNav}>
          ☰
        </div>
      </nav>
      {isMobileNavOpen && (
        <div className="mobile-nav">
          <a href="https://hive.trading/about.html" onClick={(e) => { e.preventDefault(); window.location.href = 'https://hive.trading/about.html'; }}>About Us</a>
          <a href="https://hive.trading/strategies.html" onClick={(e) => { e.preventDefault(); window.location.href = 'https://hive.trading/strategies.html'; }}>Strategies</a>
          <a href="https://hive.trading/faq.html" onClick={(e) => { e.preventDefault(); window.location.href = 'https://hive.trading/faq.html'; }}>FAQ</a>
          <a href="https://hive.trading/help.html" onClick={(e) => { e.preventDefault(); window.location.href = 'https://hive.trading/help.html'; }}>Help</a>
        </div>
      )}
      <Carousel /> {/* Embed the Carousel component here */}
      <div className="welcome-container">
        <h1>Welcome to Hive.Trading!</h1>
        <p>Activate trading bots below:</p>
      </div>
      <div className="login-container"> {/* Add this wrapper for additional spacing */}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/login-confirmation" element={<Logout />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </div>
      <footer className="footer">
        <p>Powered by Helios</p>
        <a href="https://helios.surf" target="_blank" rel="noopener noreferrer">
          <img src="/helios-small.png" alt="Helios Logo" className="footer-logo" />
        </a>
        <p>hive.trading &copy; {currentYear}</p>
      </footer>
    </div>
  );
}

export default App;
