import React from 'react';
import { ReactComponent as HivetradingLogo } from './hivetrading-logo.svg';

const LogoImage = () => {
  return (
    <HivetradingLogo />
  );
};

export default LogoImage;
