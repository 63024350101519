import React from 'react';
import { ReactComponent as HivetradingText } from './hivetrading-text.svg';

const LogoText = () => {
  return (
    <HivetradingText />
  );
};

export default LogoText;
