import React, { useState } from "react";
import axios from "axios";
import { storeUsername } from '../utils/keychainFrontendUtils';

const hive = require('@hiveio/hive-js');

const Login = () => {
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [loginType, setLoginType] = useState("");
  const [botType, setBotType] = useState("bollinger"); // Default to Bollinger Band bot

  const displayLoginKeychain = () => {
    if (window.hive_keychain) {
      let keychain = window.hive_keychain;
      keychain.requestHandshake(() => {
        setLoginType("keychain");
      });
    } else {
      setError("You do not have Hive Keychain installed");
    }
  };

  const fetchMemo = async () => {
    try {
      const response = await axios.post('/auth/keychain/fetch_memo', { username });
      console.log('Fetched memo:', response.data);
      return response.data;
    } catch (error) {
      setError("Failed to fetch memo: " + error.message);
      console.error("Failed to fetch memo:", error);
      return null;
    }
  };

  const loginToServer = async (memo) => {
    try {
      console.log('Logging in to server with memo:', memo.message);
      const loginResponse = await axios.post('/auth/keychain/login', {
        username,
        encrypted_username: memo.message,
        botType // Send the bot type to the server
      });

      if (loginResponse.data.status === "ok") {
        const token = loginResponse.data.account.token;
        console.log('Token received:', token);
        storeUsername(username, botType); // Pass botType to storeUsername
        window.location.href = `/login-confirmation.html?username=${username}&token=${token}&botType=${botType}`;
      } else {
        setError("Keychain login failed");
        console.error("Keychain login failed:", loginResponse.data);
      }
    } catch (error) {
      setError("Login failed: " + error.message);
      console.error("Login failed:", error);
    }
  };

  const sendLoginToken = async () => {
    const memo = await fetchMemo();
    if (!memo || memo.status !== "ok") {
      setError("There was an error with the backend server, please try again");
      return;
    }

    await loginToServer(memo);
  };

  const loginKeychain = async (event) => {
    event.preventDefault();

    if (!window.hive_keychain) {
      setError("You do not have Hive Keychain installed");
      return;
    }

    try {
      let data = await hive.api.getAccountsAsync([username]);
      if (data.length !== 1) {
        setError("Hive user not found");
        return;
      }

      let auth = data[0].active.account_auths.filter(el => el[0] === "helios.trader");
      if (auth.length === 0) {
        console.log("Adding active authority...");
        window.hive_keychain.requestAddAccountAuthority(username, "helios.trader", "active", 1, async (response) => {
          if (response.success) {
            console.log("Active authority added successfully:", response);
            storeUsername(username, botType); // Pass botType to storeUsername
            await sendLoginToken();
          } else {
            console.error("Failed to add active authority:", response.message);
            setError("Keychain error: Could not add active authority.");
          }
        });
      } else {
        console.log("Active authority already exists. Redirecting...");
        storeUsername(username, botType); // Pass botType to storeUsername
        await sendLoginToken();
      }
    } catch (error) {
      setError("Error fetching account data: " + error.message);
      console.error("Error fetching account data:", error);
    }
  };

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <div className="fadeIn first">
          <img src="./hive_symbol.png" alt="hive icon" style={{ width: "30px", marginTop: "10px" }} />
        </div>

        <span style={{ color: "red" }}>{error}</span>

        {loginType === "" ? (
          <>
            <select onChange={(e) => setBotType(e.target.value)} value={botType}>
              <option value="bollinger">Bot 1: Bollinger Band</option>
              <option value="sma">Bot 2: SMA</option>
              <option value="scalpybb">Bot 3: SCALPY BB</option>
              <option value="marketmaker">Bot 4: Market Maker</option> {/* New option added */}
            </select>
            <button onClick={displayLoginKeychain} style={{ backgroundColor: "white", color: "#999999", width: "235px", marginTop: "20px", border: "1px solid #999999", borderRadius: "0" }}>
              Log in with keychain
            </button>
          </>
        ) : (
          <form onSubmit={loginKeychain}>
            <input type="text" placeholder="Username" value={username} onChange={(event) => setUsername(event.target.value)} />
            <button type="submit" style={{ backgroundColor: "white", color: "#999999", width: "235px", marginTop: "20px", border: "1px solid #999999", borderRadius: "0" }}>
              Log in with keychain
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
