import React, { useEffect } from 'react';
import './Carousel.css';

const Carousel = () => {
  useEffect(() => {
    const localScript = document.createElement('script');
    localScript.src = '/scripts.js';
    localScript.async = true;
    document.body.appendChild(localScript);

    return () => {
      document.body.removeChild(localScript);
    };
  }, []);

  return (
    <div className='post-nav'>
      <div className='carousel'>
        <div className='posts-container'></div>
      </div>
    </div>
  );
};

export default Carousel;
