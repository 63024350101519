export const storeUsername = (username, botType) => {
    localStorage.setItem('hive-username', username);
    localStorage.setItem('bot-type', botType); // Store the bot type in local storage
};

export const getUsername = () => {
    return localStorage.getItem('hive-username');
};

export const getBotType = () => {
    return localStorage.getItem('bot-type'); // Retrieve the bot type from local storage
};
